

const Listening = () => {
    return (
        <div className="absolute align-middle top-1 right-2 mt-2 ml-2 text-[#cc0000] text-md font-base flex items-center">
            <div className="w-3 h-3 bg-[#cc0000] rounded-full animate-pulse text-[#cc0000]"></div>
            <span className="ml-2 text-[#cc0000]">Listening...</span>
        </div>
    )

}

export default Listening;