import React from 'react';

const Subtitle = ({ message }) => {
    return (
        <div className={`z-50 closed-caption text-sm w-full ${message.role === "agent" ? "text-white" : "text-blue-300"}`}>
            <p className="p-2">{ message.content }</p>
        </div>
    );
};

export default Subtitle;
