import React, { useState, useEffect } from 'react';
import {
    FaMicrophone,
    FaCirclePause,
    FaCirclePlay,
    FaRotate,
    FaCircleStop,
} from "react-icons/fa6";
import ControlButton from "./controls/ControlButton";

const Controls = ({ handleStartAvatarButton, handleStartRecognitionButton, handleStopSpeakingButton, handleStopRecognitionButton, avatarSpeaking, avatarReady, isListening, langchainSocketReady }) => {
    const [recognitionReady, setRecognitionReady] = useState(false);
    const [pauseRecognition, setPauseRecognition] = useState(false);
    const [startButtonEnabled, setStartButtonEnabled] = useState(false);

    useEffect(() => {
        if (avatarSpeaking) {
            setRecognitionReady(false);
        } else if (!avatarSpeaking && avatarReady && langchainSocketReady) {
            setRecognitionReady(true);
        };
    }, [avatarSpeaking, avatarReady, langchainSocketReady]);

    useEffect(() => {
        if (isListening && avatarReady) {
            setPauseRecognition(true);
        } else {
            setPauseRecognition(false);
        };
    }, [avatarReady, isListening]);

    useEffect(() => {
        if (langchainSocketReady && !avatarReady) {
            setStartButtonEnabled(true);
        } else if (avatarReady) {
            console.log('Avatar Ready');
            setStartButtonEnabled(false);
        };

    }, [avatarReady, langchainSocketReady]);

    const buttons = [
        { icon: <FaCirclePlay />, key: 'start', action: { handleStartAvatarButton }, tooltip: 'Start Avatar', disabled: !startButtonEnabled, glow: true },
        { icon: <FaMicrophone />, key: 'microphone', action: { handleStartRecognitionButton }, tooltip: 'Start Recognition', disabled: !recognitionReady, glow: true },
        { icon: <FaCircleStop />, key: 'stop', action: { handleStopSpeakingButton }, tooltip: 'Stop Speaking', disabled: !avatarSpeaking, glow: false },
        { icon: <FaCirclePause />, key: 'pause', action: { handleStopRecognitionButton }, tooltip: 'Pause Recognition', disabled: !pauseRecognition, glow: false },
        { icon: <FaRotate />, key: 'refresh', action: { handleStopRecognitionButton }, tooltip: 'Stop Recognition', disabled: false, glow: false },
    ];

    return (
        <div className="absolute top-1/3 left-2 flex items-center justify-center">
            <ul className="flex flex-col items-center">
                {buttons.map(button => (
                    <li key={button.key} className="p-1.5">
                        <ControlButton
                            icon={button.icon}
                            action={Object.values(button.action)[0]}
                            tooltip={button.tooltip}
                            disabled={button.disabled}
                            glow={button.glow} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Controls;